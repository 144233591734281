(<template>
  <button class="edit-pencil-btn"
          @click="edit"></button>
</template>)
<script>
  import {mapState} from 'vuex';

  export default {
    props: {
      sectionName: {
        type: String,
        default: ''
      },
      validateFunc: {
        type: String,
        default: ''
      },
      fetchFunc: {
        type: String,
        default: ''
      },
      modalClasses: {
        type: Array,
        default: () => []
      },
      subtasksTypes: {
        type: Array,
        default: () => []
      },
      sourceLang: {
        type: Number,
        default: null
      }
    },
    computed: {
      ...mapState('OneProjectStore', {
        project: ({project}) => project || {},
      }),
      ...mapState('PostingStore/BookerStore', {
        booker: ({booker}) => booker
      }),
      projectId() { return this.$route.params.id; },
      jobStatus() { return this.project.status || ''; },
      isPublishedJob() { return this.jobStatus === 'published'; },
    },
    methods: {
      edit() {
        this.$store.commit('ErrorsStore/removeAllErrors');
        // this.$store.dispatch('PostTranslationProjectStore/clearStore');
        if (this.sourceLang === null) {
          this.$store.commit('PostingStore/LanguagesStore/setNoSourceLang', true);
        }
        this.$store.commit('PostTranslationProjectStore/setProjectInfo', {
          project: this.project,
          rootGetters: this.$store.getters,
          rootState: this.$store.state
        });
        if (this.fetchFunc) {
          this.$store.dispatch(this.fetchFunc)
            .then(() => {
              this.openEditModal();
            });
        } else {
          this.openEditModal();
        }
      },
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'project-info-edit-modal',
          classes: ['sk-modal--new', ...this.modalClasses],
          data: {
            sectionName: this.sectionName,
            validateFuncName: this.validateFunc,
            successHandlingFunc: this.successHandlingFunc,
            sendInfoFunc: this.sendInfoFunc,
            subtasksTypes: this.subtasksTypes,
            submitButtonText: this.$pgettext('translation', 'Confirm changes')
          }
        });
      },
      async sendInfoFunc() {
        this.$store.commit('ModalStore/startModalProgress');
        this.$store.commit('PostTranslationProjectStore/setTrackType', 'order_updated');
        const previousBooker = this.project.owner.uid;
        await this.$store.dispatch('PostTranslationProjectStore/editProject');
        if (this.booker !== previousBooker) {
          this.$router.replace(this.$makeRoute({name: 'BuyerHome'}));
        }
      },

      successHandlingFunc() {
        this.$store.dispatch('ModalStore/closeModal');
        this.$store.commit('ModalStore/stopModalProgress');
        // TODO: update data after update
        // this.$store.dispatch('OneProjectStore/getProjectById', this.projectId).then(() => {
        //   if (this.isPublishedJob) {
        //     this.$store.commit('FAIListStore/clearSuitableInterpreters');
        //     this.$emit('fetchsuitableinterpreters');
        //   }
        // });
      }
    }
  };
</script>
